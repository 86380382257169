// import React, { useEffect } from "react";
// import "ol/ol.css";
// import Map from "ol/Map";
// import View from "ol/View";
// import TileLayer from "ol/layer/Tile";
// import { OSM, TileWMS } from "ol/source";

// const BhuvanMap = () => {
//   useEffect(() => {
//     const map = new Map({
//       target: "map",
//       layers: [
//         new TileLayer({
//           source: new OSM(),
//         }),
//         new TileLayer({
//           source: new TileWMS({
//             url: "https://bhuvan-vec2.nrsc.gov.in/bhuvan/wms",
//             params: {
//               layers: "lulc:UP_LULC50K_1516",
//             },
//           }),
//         }),
//       ],
//       view: new View({
//         center: [26.756, 83.095],
//         zoom: 2,
//       }),
//     });
//   }, []);

//   return <div id="map" style={{ width: "100%", height: "400px" }}></div>;
// };

// export default BhuvanMap;


import React, { useEffect } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const BhuvanMap = () => {
  useEffect(() => {
    const map = L.map("map").setView([26.756, 83.095], 2);

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
    }).addTo(map);

    L.tileLayer.wms("https://bhuvan-vec2.nrsc.gov.in/bhuvan/wms", {
      layers: "lulc:UP_LULC50K_1516",
    }).addTo(map);
  }, []);

  return <div id="map" style={{ width: "100%", height: "400px" }}></div>;
};

export default BhuvanMap;
