export const coordiantesOfBoundary = [
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [83.092664445573433, 26.756769885293547],
              [83.100995961191117, 26.754754778924468],
              [83.106525779141492, 26.753349964111152],
              [83.102704800671148, 26.751142710508031],
              [83.102478644605853, 26.750287252680053],
              [83.102704086537543, 26.749998635262404],
              [83.103498472823631, 26.749285768700656],
              [83.104649621840807, 26.748464591073443],
              [83.106047281590463, 26.747187729880103],
              [83.106675636680137, 26.746389020090152],
              [83.107158328855846, 26.745369264734499],
              [83.105620536513356, 26.744137070015402],
              [83.104439621344738, 26.742900466402247],
              [83.103112937793824, 26.741474259061402],
              [83.101715496178272, 26.742403573605209],
              [83.100825730910216, 26.743030681744852],
              [83.099885656156204, 26.743766096778504],
              [83.098030679812709, 26.744785614648386],
              [83.096713725712235, 26.745110299996771],
              [83.09598995385852, 26.745218473047043],
              [83.094954567759032, 26.745024166262397],
              [83.094230806798848, 26.744848078144617],
              [83.094110037372147, 26.745367007431827],
              [83.094135007213922, 26.745994244884812],
              [83.093843318118957, 26.746883192827866],
              [83.093244867970569, 26.747726965000201],
              [83.092304690268662, 26.748742077361488],
              [83.090710971419668, 26.749693820042779],
              [83.089502396168655, 26.750289982880751],
              [83.085969412459306, 26.75221296686372],
              [83.081321501984178, 26.753167987565412],
              [83.078986911356054, 26.753213806449217],
              [83.081396607292618, 26.756250960752869],
              [83.083797299210815, 26.758918199772939],
              [83.084642384454384, 26.759765191293624],
              [83.08626628774654, 26.758746257905237],
              [83.092664445573433, 26.756769885293547],
            ],
          ],
        ],
      },
    },
  ];
