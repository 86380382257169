// import React, { useEffect } from 'react';
// import Highcharts from 'highcharts';
// import HC_exporting from 'highcharts/modules/exporting';
// import { GramPanchayatWiseData } from '../GramPanchayatWiseData'; // Import your data file

// HC_exporting(Highcharts);

// const dataa = GramPanchayatWiseData.SantKabirNagar.SourceOfIncome; // Access the data for "Nehiya Khurd"


// const SourceOfIncome = () => {
//   useEffect(() => {
//     // Define your data values
//     const data = dataa;

//     // Calculate the total value
//     const total = data.reduce((sum, item) => sum + item.value, 0);

//     // Calculate percentages
//     const percentages = data.map((item) => ({
//       name: item.name,
//       y: (item.value / total) * 100,
//     }));

//     // Create the Highcharts chart inside the useEffect hook
//     Highcharts.chart('SourseOfIncome', {
//       chart: {
//         plotBackgroundColor: null,
//         plotBorderWidth: null,
//         plotShadow: false,
//         type: 'pie',
//       },
//       title: {
//         text: 'Source of income (Percentage)',
//         align: 'center',
//       },
//       tooltip: {
//         pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
//       },
//       accessibility: {
//         point: {
//           valueSuffix: '%',
//         },
//       },
//       plotOptions: {
//         pie: {
//           allowPointSelect: true,
//           cursor: 'pointer',
//           dataLabels: {
//             enabled: true,
//             format: '<b>{point.name}</b>: {point.percentage:.1f} %',
//           },
//         },
//       },
//       series: [
//         {
//           name: 'Activities',
//           colorByPoint: true,
//           data: percentages,
//         },
//       ],
//     });
//   }, []);

//   return <div id="SourseOfIncome" />;
// };

// export default SourceOfIncome;


import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { GramPanchayatWiseData } from '../GramPanchayatWiseData';
import { useInView } from 'react-intersection-observer';

HC_exporting(Highcharts);

const dataa = GramPanchayatWiseData.SantKabirNagar.SourceOfIncome;

const SourceOfIncome = () => {
  const [ref, inView] = useInView({
    triggerOnce: false, // Trigger only once when it comes into view
    // threshold: 0.2, // 20% of the component should be in view
  });

  useEffect(() => {
    if (inView) {
      const data = dataa;
      const total = data.reduce((sum, item) => sum + item.value, 0);
      const percentages = data.map((item) => ({
        name: item.name,
        y: (item.value / total) * 100,
      }));

      Highcharts.chart('SourceOfIncome', {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
        },
        title: {
          text: 'Source of income (Percentage)',
          align: 'center',
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
        },
        accessibility: {
          point: {
            valueSuffix: '%',
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            },
          },
        },
        series: [
          {
            name: 'Activities',
            colorByPoint: true,
            data: percentages,
          },
        ],
      });
    }
  }, [inView]);

  return <div ref={ref} id="SourceOfIncome" />;
};

export default SourceOfIncome;
