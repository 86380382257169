// import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
// import i18n from './i18n';

// const LanguageChange = () => {
//   const { t } = useTranslation();
//   const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'en');

//   const changeLanguage = (lng) => {
//     i18n.changeLanguage(lng);
//     setSelectedLanguage(lng);
//     localStorage.setItem('language', lng);
//   };

//   useEffect(() => {
//     i18n.changeLanguage(selectedLanguage);
//   }, [selectedLanguage]);

//   return (
//     <div>
//       {/* <h1>{t('welcome')}</h1> */}
//       <select value={selectedLanguage} onChange={(e) => changeLanguage(e.target.value)}>
//         <option value="en">English</option>
//         <option value="hi">Hindi</option>
//         {/* Add more options for other languages */}
//       </select>
//     </div>
//   );
// };

// export default LanguageChange;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";

const LanguageChange = () => {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("language") || "en"
  );

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
    localStorage.setItem("language", lng);
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const getLanguageStyle = (language) => {
    return {
      cursor: "pointer",
      marginLeft: "10px",
      marginRight: "10px",
      color: selectedLanguage === language ? "red" : "white",
    };
  };

  return (
    <div style={{ color: "white" }}>
      <span style={getLanguageStyle("en")} onClick={() => changeLanguage("en")}>
        EN
      </span>
      |
      <span style={getLanguageStyle("hi")} onClick={() => changeLanguage("hi")}>
       हि
      </span>
      {/* Add more spans for other languages */}
    </div>
  );
};

export default LanguageChange;
