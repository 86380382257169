// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      welcome: 'Welcome to our website!',
      tagline:'A Greener Future for All',
      location:'Location',
      locationValue:'Khalilabad Block, Sant Kabeer Kabir Nagar District',
      totalArea:'Total Area',
      totalAreaValue:'202 ha',
      composition:'Composition',
      compositionValue:'1 revenue village 3 hamlets',
      agroClimateZone:'Agro-climatic zone',
      agroClimateZoneValue:'North-eastern plains',
      CompositeVulnerability:'Composite Vulnerability',
      CompositeVulnerabilityValue:'0.56 (highly vulnerable)Moderate',
      totalHouses:'Total Houses',
      totalHousesValue:'438',
      totalPRIBuildings:'Total PRI buildings',
      totalPRIBuildingsValue:'5 - Panchayat Bhawan, Primary School, CommunityHall, ASHA Centre and Anganwadi',
      PrimaryEconomicActivity:'Primary Economic Activity',
      PrimaryEconomicActivityValue:'Agriculture',
    },
  },
  hi: {
    translation: {
      welcome: 'हमारी वेबसाइट में आपका स्वागत है!',
      tagline:'सभी के लिए एक हरा-भरा भविष्य',
      location:'जिला और ब्लॉक ',
      locationValue:'खलीलाबाद ब्लॉक, संत कबीर कबीर नगर जिला',
      totalArea:'कुल क्षेत्रफल',
      totalAreaValue:'202 हेक्टेयर',
      composition:'संघटन',
      compositionValue:'1 राजस्व ग्राम 3 टोले',
      agroClimateZone:'कृषि-जलवायु क्षेत्र',
      agroClimateZoneValue:'उत्तर-पूर्वी मैदान',
      CompositeVulnerability:'समग्र भेद्यता',
      CompositeVulnerabilityValue:'0.56 (अत्यधिक संवेदनशील) मध्यम',
      totalHouses:'कुल मकान',
      totalHousesValue:'438',
      totalPRIBuildings:'कुल पी आर आई भवन',
      totalPRIBuildingsValue:'5 - पंचायत भवन, प्राथमिक विद्यालय, सामुदायिक भवन, आशा केंद्र और आंगनवाड़ी',
      PrimaryEconomicActivity:'प्राथमिक आर्थिक गतिविधि',
      PrimaryEconomicActivityValue:'कृषि',
   
    },
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('language') || 'en', // set the default language from local storage or 'en'
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });


export default i18n;