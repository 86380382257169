import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { GramPanchayatWiseData } from '../GramPanchayatWiseData';
import { useInView } from 'react-intersection-observer';

HC_exporting(Highcharts);

const data = GramPanchayatWiseData.SantKabirNagar.Temperaturedata;

const Temperature = () => {
  const [ref, inView] = useInView({
    triggerOnce: false, // Set triggerOnce to false to detect when it enters and exits the view
  });

  useEffect(() => {
    if (inView) {
      // Create the Highcharts chart when the component enters view
      const chart = Highcharts.chart('Temperature', {
        chart: {
          scrollablePlotArea: {
            minWidth: 700,
          },
        },
        title: {
          text: 'Temperature',
        },
        series: [
          {
            name: 'Min Temperature',
            data: data.map((item) => [item.year, item.minTemp]),
          },
          {
            name: 'Max Temperature',
            data: data.map((item) => [item.year, item.maxTemp]),
          },
        ],
      });

      return () => {
        // Destroy the chart when the component exits view to prevent memory leaks
        chart.destroy();
      };
    }
  }, [data, inView]);

  return <div ref={ref} id="Temperature" className="temperature" />;
};

export default Temperature;
