import React from "react";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
import {
  AiOutlineZoomIn,
  AiOutlineZoomOut,
  AiOutlineReload,
} from "react-icons/ai"; // Import the icons
import landuse from "../images/LandUseMap.svg";
import Hero from "../Hero";
import legend from "../images/Legend.svg"

function LandUse() {
  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
      <div
        style={{
          bottom: 0,
          right: 100,
          zIndex: "10000",
        //   position: "absolute", // Set position to absolute
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "space-between",
            justifyContent: "space-around",
            // margin: "10px",
            cursor: "hand",
            width:'40px',
            marginLeft:'10px'
          }}
        >
          <button className="mb-2 btn btn-primary" onClick={() => zoomIn()}>
            <AiOutlineZoomIn />{" "}
          </button>
          <button className="my-2 btn btn-primary" onClick={() => zoomOut()}>
            <AiOutlineZoomOut />{" "}
          </button>
          <button
            className="my-2 btn btn-primary"
            onClick={() => resetTransform()}
          >
            <AiOutlineReload />{" "}
          </button>
        </div>
      </div>
    );
  };

  return (
   <div>
     <div className="mt-5" style={{width:'100%', margin:'0px auto', display:'flex',
    justifyContent:'center'}}>
        <TransformWrapper
      scaleSensitivity={0.1} // Adjust the sensitivity as needed
      minScale={0.5} // Set the minimum scale factor
    >
      <TransformComponent>
        <img
          src={landuse}
          // src="https://images.unsplash.com/photo-1506744038136-46273834b3fb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          alt="test"
          
          width="100%"
          style={{
            // position: "relative", 
            maxHeight:'500px'
          }}
        />
        {/* <Hero/> */}
      </TransformComponent>
      <Controls />
    </TransformWrapper>
    </div>
    
<div className="landUseLegendDiv">
  
<img className="landUseLegend" style={{marginLeft:'150px',marginTop:'-200px', zIndex:'1000000', position:'relative'}} src={legend}/>
   
</div>
   
   </div>
  );
}

export default LandUse;
