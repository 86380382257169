// import React, { useEffect } from 'react';
// import Highcharts from 'highcharts';
// import { GramPanchayatWiseData } from '../GramPanchayatWiseData'; // Import your data file


// // const rainfallData = [
// //     { year: 1986, rainfall: 1138 },
// //     { year: 1987, rainfall: 1140 },
// //     { year: 1988, rainfall: 1532 },
// //     { year: 1989, rainfall: 1562 },
// //     { year: 1990, rainfall: 1371 },
// //     { year: 1991, rainfall: 1058 },
// //     { year: 1992, rainfall: 1006 },
// //     { year: 1993, rainfall: 1524 },
// //     { year: 1994, rainfall: 1082 },
// //     { year: 1995, rainfall: 2004 },
// //     { year: 1996, rainfall: 2167 },
// //     { year: 1997, rainfall: 140 },
// //     { year: 1998, rainfall: 1439 },
// //     { year: 1999, rainfall: 1063 },
// //     { year: 2000, rainfall: 1691 },
// //     { year: 2001, rainfall: 1484 },
// //     { year: 2002, rainfall: 906 },
// //     { year: 2003, rainfall: 967 },
// //     { year: 2004, rainfall: 921 },
// //     { year: 2005, rainfall: 1096 },
// //     { year: 2006, rainfall: 670 },
// //     { year: 2007, rainfall: 629 },
// //     { year: 2008, rainfall: 837 },
// //     { year: 2009, rainfall: 449 },
// //     { year: 2010, rainfall: 622 },
// //     { year: 2011, rainfall: 1162 },
// //     { year: 2012, rainfall: 1176 },
// //     { year: 2013, rainfall: 797 },
// //     { year: 2014, rainfall: 665 },
// //     { year: 2015, rainfall: 437 },
// //     { year: 2016, rainfall: 889 },
// //     { year: 2017, rainfall: 600 },
// //     { year: 2018, rainfall: 590 },
// //     { year: 2019, rainfall: 802 },
// //     { year: 2020, rainfall: 1102 },
// //     { year: 2021, rainfall: 1253 },
// //   ];

//   const rainfallData = GramPanchayatWiseData.SantKabirNagar.rainfallData; // Access the data for "Nehiya Khurd"


// const Rainfall = () => {
//   useEffect(() => {
//     // Create the Highcharts chart inside the useEffect hook
//     Highcharts.chart('Rainfall', {
//       chart: {
//         type: 'column'
//       },
//       title: {
//         text: "Rainfall"
//       },
//     //   subtitle: {
//     //     text: (
//     //       <span>
//     //         Source: <a href="https://worldpopulationreview.com/world-cities" target="_blank" rel="noopener noreferrer">World Population Review</a>
//     //       </span>
//     //     )
//     //   },
//       xAxis: {
//         type: 'category',
//         labels: {
//           rotation: -45,
//           style: {
//             fontSize: '13px',
//             fontFamily: 'Verdana, sans-serif'
//           }
//         }
//       },
//       yAxis: {
//         min: 0,
//         title: {
//           text: 'Rainfall (in mm)'
//         }
//       },
//       legend: {
//         enabled: false
//       },
//       tooltip: {
//         pointFormat: 'Rainfall: <b>{point.y:.1f} </b> (in mm)'
//       },
//       series: [{
//         name: 'Rainfall',
//         colors: [
//           '#4caee3'
//         ],
//         colorByPoint: true,
//         groupPadding: 0,
//         data: rainfallData.map((item) => [item.year.toString(), item.rainfall]),
//         dataLabels: {
//           enabled: false,
//           rotation: -90,
//           color: '#FFFFFF',
//           align: 'right',
//           format: '{point.y:.1f}', // one decimal
//           y: 10, // 10 pixels down from the top
//           style: {
//             fontSize: '13px',
//             fontFamily: 'Verdana, sans-serif'
//           }
//         }
//       }]
//     });
//   }, []);

//   return <div id="Rainfall" />;
// };

// export default Rainfall;

import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import { GramPanchayatWiseData } from '../GramPanchayatWiseData';
import { useInView } from 'react-intersection-observer';

const rainfallData = GramPanchayatWiseData.SantKabirNagar.rainfallData;

const Rainfall = () => {
  const [ref, inView] = useInView({
    triggerOnce: false,
  });

  useEffect(() => {
    if (inView) {
      Highcharts.chart('Rainfall', {
        chart: {
          type: 'column',
        },
        title: {
          text: 'Rainfall',
        },
        xAxis: {
          type: 'category',
          labels: {
            rotation: -45,
            style: {
              fontSize: '13px',
              fontFamily: 'Verdana, sans-serif',
            },
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Rainfall (in mm)',
          },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          pointFormat: 'Rainfall: <b>{point.y:.1f} </b> (in mm)',
        },
        series: [
          {
            name: 'Rainfall',
            colors: ['#4caee3'],
            colorByPoint: true,
            groupPadding: 0,
            data: rainfallData.map((item) => [item.year.toString(), item.rainfall]),
            dataLabels: {
              enabled: false,
              rotation: -90,
              color: '#FFFFFF',
              align: 'right',
              format: '{point.y:.1f}',
              y: 10,
              style: {
                fontSize: '13px',
                fontFamily: 'Verdana, sans-serif',
              },
            },
          },
        ],
      });
    }
  }, [inView]);

  return <div ref={ref} id="Rainfall" />;
};

export default Rainfall;


