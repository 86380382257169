export const GramPanchayatWiseData = {
  SantKabirNagar: {
    GPName: "Nehiya Khurd",
    Location: "Khalilabad Block, Sant Kabeer Kabir Nagar District",
    TotalArea: "202 ha",
    Composition: "1 revenue village and 3 hamlets",
    TotalPopulation: "2700",
    NoOfMales: "1400",
    NoOfFemales: "1300",
    TotalHouseholds: "438",
    TotalPRIbuildings:
      "5 - Panchayat Bhawan, Primary School, Community Hall (in good condition), ASHA Centre and Anganwadi (need repairs/ renovation)",
    PrimaryEconomicActivity: "Agriculture",
    AgroClimaticZone: "North-eastern plains",
    LandUse: {
      AgricultureLand: "150 ha",
      AgroForestryPlantation: "1.6 ha",
      CommonLand: "0.6 ha",
      WaterBodies: "4 ponds (includes 1 2 Amrit Sarovars)",
    },
    CompositeVulnerabilityOfDistrict: "0.56 (highly vulnerable)Moderate",
    ClimateVariability:
      "The number of rainy days has also decreased by 20-25 days. Annual average rainfall in the GP shows a declining trend between 1986 and 2021",
    Temperaturedata: [
      { year: 1986, minTemp: 16.1, maxTemp: 28.27 },
      { year: 1987, minTemp: 16.74, maxTemp: 29.22 },
      { year: 1988, minTemp: 18.98, maxTemp: 31.59 },
      { year: 1989, minTemp: 15.41, maxTemp: 28.53 },
      { year: 1990, minTemp: 15.23, maxTemp: 28.36 },
      { year: 1991, minTemp: 15.89, maxTemp: 28.92 },
      { year: 1992, minTemp: 18.82, maxTemp: 32.03 },
      { year: 1993, minTemp: 15.43, maxTemp: 28.57 },
      { year: 1994, minTemp: 16.6, maxTemp: 28.73 },
      { year: 1995, minTemp: 16.31, maxTemp: 29.28 },
      { year: 1996, minTemp: 18.78, maxTemp: 31.69 },
      { year: 1997, minTemp: 16.2, maxTemp: 27.93 },
      { year: 1998, minTemp: 17.25, maxTemp: 28.38 },
      { year: 1999, minTemp: 16.59, maxTemp: 28.66 },
      { year: 2000, minTemp: 18.83, maxTemp: 31.14 },
      { year: 2001, minTemp: 16.15, maxTemp: 28.49 },
      { year: 2002, minTemp: 16.83, maxTemp: 28.96 },
      { year: 2003, minTemp: 16.33, maxTemp: 28.07 },
      { year: 2004, minTemp: 19.2, maxTemp: 31.34 },
      { year: 2005, minTemp: 16.86, maxTemp: 29.25 },
      { year: 2006, minTemp: 16.69, maxTemp: 29.22 },
      { year: 2007, minTemp: 16.29, maxTemp: 28.48 },
      { year: 2008, minTemp: 19.26, maxTemp: 31.43 },
      { year: 2009, minTemp: 16.89, maxTemp: 29.48 },
      { year: 2010, minTemp: 17.46, maxTemp: 29.58 },
      { year: 2011, minTemp: 16.46, maxTemp: 28.52 },
      { year: 2012, minTemp: 19.13, maxTemp: 31.99 },
      { year: 2013, minTemp: 16.37, maxTemp: 28.6 },
      { year: 2014, minTemp: 16.38, maxTemp: 28.48 },
      { year: 2015, minTemp: 17.01, maxTemp: 29.02 },
    ],
    rainfallData: [
      { year: 1986, rainfall: 1138 },
      { year: 1987, rainfall: 1140 },
      { year: 1988, rainfall: 1532 },
      { year: 1989, rainfall: 1562 },
      { year: 1990, rainfall: 1371 },
      { year: 1991, rainfall: 1058 },
      { year: 1992, rainfall: 1006 },
      { year: 1993, rainfall: 1524 },
      { year: 1994, rainfall: 1082 },
      { year: 1995, rainfall: 2004 },
      { year: 1996, rainfall: 2167 },
      { year: 1997, rainfall: 140 },
      { year: 1998, rainfall: 1439 },
      { year: 1999, rainfall: 1063 },
      { year: 2000, rainfall: 1691 },
      { year: 2001, rainfall: 1484 },
      { year: 2002, rainfall: 906 },
      { year: 2003, rainfall: 967 },
      { year: 2004, rainfall: 921 },
      { year: 2005, rainfall: 1096 },
      { year: 2006, rainfall: 670 },
      { year: 2007, rainfall: 629 },
      { year: 2008, rainfall: 837 },
      { year: 2009, rainfall: 449 },
      { year: 2010, rainfall: 622 },
      { year: 2011, rainfall: 1162 },
      { year: 2012, rainfall: 1176 },
      { year: 2013, rainfall: 797 },
      { year: 2014, rainfall: 665 },
      { year: 2015, rainfall: 437 },
      { year: 2016, rainfall: 889 },
      { year: 2017, rainfall: 600 },
      { year: 2018, rainfall: 590 },
      { year: 2019, rainfall: 802 },
      { year: 2020, rainfall: 1102 },
      { year: 2021, rainfall: 1253 },
    ],
    KeyEconomicProfile:
      "Agriculture is the key source of income in the GP (nearly 74% of the total GP area is under cultivation)",
    SourceOfIncome: [
      { name: "Service Sector", value: 25 },
      { name: "Small Scale/Cottage Industries", value: 80 },
      { name: "Agriculture", value: 250 },
      { name: "Arts/Handicrafts", value: 2 },
      { name: "Animal Husbandry", value: 50 },
      { name: "Business (Local Shops)", value: 20 },
      { name: "Wage labour (non-farm)", value: 150 },
      { name: "Others", value: 40 },
    ],
    WomensEmpolyment:
      "50 women-headed households in the GP. There are 14 Self-Help Groups (SHG) that are mostly involved in activities like tailoring, goat rearing, and running local shops",
    WomensEmpolymentData: [
      {
        name: "Service Sector (Example: Teaching, Bank, Govt Job, etc)",
        value: 2,
      },
      { name: "Agriculture", value: 35 },
      { name: "Animal Husbandry", value: 3 },
      { name: "Arts/Handicrafts", value: 2 },
      { name: "Business (Local Shop)", value: 3 },
      { name: "Other (Stiching/Tailoring etc.)", value: 25 },
    ],
    Agriculture:
      "net sown area in Nehiya Khurd is nearly 150 ha while the gross cropped area is 254 ha. major kharif crop grown is rice (~5,625 quintal/year) and the major rabi crops grown are wheat (~4500 quintal/year), mustard (~254 quintal/year) and some vegetables",
    AgricultureOnlyDependentHouseholds: {
      "In Other arrangements": 40,
      "As Wage labourers": 150,
      "As Contract farming": 25,
      "As Tenant": 7,
      "With Own Land": 250,
    },
    CropWiseData: [
      { Crop: "Wheat", "Area (acres)": 280, "Area (ha)": 113 },
      { Crop: "Rice", "Area (acres)": 290, "Area (ha)": 117 },
      { Crop: "Mustard", "Area (acres)": 38, "Area (ha)": 15 },
      { Crop: "Vegetables", "Area (acres)": 20, "Area (ha)": 8 },
    ],

    // Aminities in GP
    // Data on map
    OverAllEmission:
      "Overall, in 2022, Nehiya Khurd GP emitted 1,200.21 tonnes of carbon dioxide equivalent (t CO2e) from a wide range of activities",
    SectorWiseEmission:
      "Within the sector, the transport category was the key emitter (~302 tCO2e), this was followed by residential cooking (178 tCO2e) and diesel pump sets (95 tCO2e)",
    SectorWiseEmissionData: [
      { name: "Agriculture", value: 41 },
      { name: "Waste", value: 11 },
      { name: "Energy", value: 48 },
    ],
    ActivityWiseEmission: [
      {
        name: "Residential Cooking",
        data: [117.89],
      },
      {
        name: "Diesel pumps",
        data: [94.45],
      },
      {
        name: "Transport",
        data: [302.39],
      },
      {
        name: "Crop Residue burning",
        data: [23.45],
      },
      {
        name: "Livestock Emissions",
        data: [412.33],
      },
      {
        name: "Fertilizer Emissions",
        data: [61.33],
      },
    ],
  },
};
