import React from 'react'
import LanguageChange from './LanguageChange'

const Navbar = () => {
  return (
    <div className='Navigation' style={{background:'#3E3E3E'}}>
      <nav className="navbar navbar-expand-lg navbar-light  ">
  {/* <a className="navbar-brand" href="#">Navbar</a> */}
  {/* <button className="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button> */}

  <div className="mx-auto" id="navbarSupportedContent">
    <ul className="navbar-nav  " style={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'row'}}>
      <li className="nav-item mx-4 " >
        <a className="nav-link" href="#"   style={{color:'white'}}>Page 1 </a>
      </li>
      <li className="nav-item mx-4">
        <a className="nav-link" href="#"  style={{color:'white'}}>Page 2 </a>
      </li>
      <li className="nav-item mx-4 ">
        <a className="nav-link" href="#"  style={{color:'white'}}>Page 3 </a>
      </li>
      <li className="nav-item mx-4">
        <a className="nav-link" href="#"  style={{color:'white'}}>Page 4 </a>
      </li>
      {/* <li className="nav-item mx-4 ">
        <a className="nav-link" href="#"  style={{color:'white'}}>Navigation5 </a>
      </li> */}
      {/* <li className="nav-item dropdown">
        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Dropdown
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
          <a className="dropdown-item" href="#">Action</a>
          <a className="dropdown-item" href="#">Another action</a>
          <div className="dropdown-divider"></div>
          <a className="dropdown-item" href="#">Something else here</a>
        </div>
      </li> */}
      
    </ul>
   
  </div>
  <div className='mr-5'>
    {/* <p className='text-white my-auto mr-lg-5'>EN | हि</p> */}
    <LanguageChange/>
  </div>
</nav>
    </div>
  )
}

export default Navbar