import React, { useEffect, useState } from 'react';
import VasudhaLogo from '../images/VasudhaLogo.jpg';
import LanguageChange from './LanguageChange';
import Navbar from './Navbar';

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the user has scrolled down by a certain amount (e.g., 100px)
      const scrollY = window.scrollY || window.pageYOffset;
      setIsSticky(scrollY > 90); // Adjust the threshold as needed
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div>
      <nav style={{overflowX:'hidden'}} className={`navbar navbar-light `}>
        <div className="header">
          <a className="navbar-brand" href="./" style={{ fontWeight: '700', fontSize: '20px', lineHeight: '26.88px' }}>
            Climate Smart Gram Panchayat <br></br> <span style={{ fontWeight: '500', fontSize: '20px', lineHeight: '29.26px' }}>Uttar Pradesh</span>
          </a>
          <div className="d-flex align-items-right ">
      
          {/* <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
          <LanguageChange/>
          </div> */}

            <img src={VasudhaLogo} alt="Logo" className="logo img-fluid" style={{ maxHeight: '60px' }} />
          </div>
        </div>

      </nav>
      <div  className={` ${isSticky ? 'sticky-nav' : ''}`}>
      <Navbar/>
      <div style={{ width: "100%", height: '11px', background: isSticky ? 'transparent' : '#009EE3' }}></div>

      </div>
      </div>
    </>
  );
};

export default Header;
