import React, { useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const LiveLocation = () => {
  const [map, setMap] = useState(null);
  const [userMarker, setUserMarker] = useState(null);
  const [circle, setCircle] = useState(null);

  useEffect(() => {
    // Create a map centered at a suitable location
    const myMap = L.map("map", {
      minZoom: 8, // Set the minimum zoom level (adjust this as needed)
    }).setView([26.756, 83.095], 5); // Adjust the initial zoom level as needed

    // Create a base map tile layer using Mapbox
    const mapboxToken =
      "pk.?eyJ1IjoiYWpheXZhc3VkaGEiLCJhIjoiY2xuNGEwY2JiMHVvYjJscGhkb2MzbmJyOSJ9.3t_skBQmlaKRCUB1W0OT6Q"; // Replace with your Mapbox access token
    const mapboxTileUrl = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${mapboxToken}`;

    const mapLayer = L.tileLayer(mapboxTileUrl, {
      attribution:
        '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors',
    });

    mapLayer.addTo(myMap);

    setMap(myMap);

    return () => {
      myMap.remove();
    };
  }, []);

  const getCurrentLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        const userLocation = [latitude, longitude];

        // Console log the latitude and longitude
        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);

        // Create or update the user marker
        if (!userMarker) {
          const marker = L.marker(userLocation).addTo(map);

          // Customize the user marker icon if needed
          marker.setIcon(L.divIcon({ className: "user-location-icon" }));

          // Add a tooltip to the user marker
          marker.bindTooltip("Your Location").openTooltip();

          setUserMarker(marker);
        } else {
          userMarker.setLatLng(userLocation);
        }

        // Optional: Pan the map to the user's location
        map.panTo(userLocation);

        // Create or update the circle with a radius of 1000 meters (1 kilometer)
        if (!circle) {
          const circleOptions = {
            color: "blue",
            fillColor: "blue",
            fillOpacity: 0.3,
            radius: 100, // 1000 meters (1 kilometer)
          };
          const newCircle = L.circle(userLocation, circleOptions).addTo(map);
          setCircle(newCircle);
        } else {
          circle.setLatLng(userLocation);
        }
      });
    } else {
      alert("Geolocation is not available in your browser.");
    }
  };

  return (
    <div>
      <div
        id="map"
        style={{ width: "100%", height: "80vh", backgroundColor: "tran" }}
      ></div>
      <button onClick={getCurrentLocation}>Get My Location</button>
    </div>
  );
};

export default LiveLocation;
