import "./App.css";
import UttarPradeshMap from "./UttarPradeshMap";
import Header from "./components/Header";
import Hero from "./Hero";
import { Redirect, Route, Switch } from "react-router-dom";
// import Temperatures from "./components/Temperature";
import Rainfall from "./components/Rainfall";
import SourceOfIncome from "./components/SourceOfIncome";
import WomensEmployment from "./components/WomensEmployment";
import TypeOfAgricultureDependentHousholds from "./components/TypeOfAgricultureDependentHousholds";
import CropWiseDistribution from "./components/CropWiseDistribution";
import SectorWiseEmission from "./components/SectorWiseEmission";
import ActivityWiseEmission from "./components/ActivityWiseEmission";
import Temperature from "./components/Temperature";
import InternalHeader from "./components/InternalHeader";
import GPProfile from "./GPProfile";
import NehiyaKhurdAmenities from "./components/NehiyaKhurdAmenities";
import LiveLocation from "./components/LiveLocation";
import LandUse from "./components/LandUse";
import GHGProfile from "./GHGProfile";
import ScrollToTop from "./components/ScrollToTop";
import { useSelector } from "react-redux";
import BhuvanMap from "./components/BhuvanMap";

function App() {
  const selectedTab = useSelector((state) => state.gramPanchayat.selectedTab);

  return (
    <div className="App">
      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          <Header />
          <Hero />
          <UttarPradeshMap />
        </Route>
        <Route exact path="/Temperature">
          <Temperature />
        </Route>
        <Route exact path="/Rainfall">
          <Rainfall />
        </Route>
        <Route exact path="/SourceOfIncome">
          <SourceOfIncome />
        </Route>
        <Route exact path="/WomensEmployment">
          <WomensEmployment />
        </Route>
        {/* <Route exact path="/TypeOfAgricultureDependentHousholds">
          <TypeOfAgricultureDependentHousholds/>
        </Route> */}
        <Route exact path="/Cropwisedistribution">
          <CropWiseDistribution />
        </Route>
        <Route exact path="/SectorWiseEmission">
          <SectorWiseEmission />
        </Route>
        <Route exact path="/ActivityWiseEmission">
          <ActivityWiseEmission />
        </Route>

        {/* <Route exact path="/SantKabirNagar">
          <InternalHeader />
          {selectedTab}
          <GPProfile />
        </Route> */}

        <Route exact path="/SantKabirNagar">
          <InternalHeader />
          {selectedTab === "GP Profile" ? (
            <GPProfile />
          ) : selectedTab === "GHG Profile" ? (
            <GHGProfile />
          ) : null}
        </Route>

        <Route exact path="/all">
          <InternalHeader />
          <ActivityWiseEmission />
          <CropWiseDistribution />
          <Rainfall />
          <SectorWiseEmission />
          <SourceOfIncome />
          <Temperature />
          <WomensEmployment />
          <TypeOfAgricultureDependentHousholds />
        </Route>

        <Route exact path="/ameneties">
          <NehiyaKhurdAmenities />
        </Route>

        <Route exact path="/LiveLocation">
          <LiveLocation />
        </Route>

        <Route exact path="/LandUse">
          <LandUse />
        </Route>

        <Route exact path="/GHGProfile">
          <InternalHeader />
          <GHGProfile />
        </Route>



        <Route exact path="/BhuvanMap">
        <BhuvanMap/>
        </Route>
      </Switch>
    </div>
  );
}

export default App;
