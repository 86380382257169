// import React, { useEffect } from 'react';
// import Highcharts from 'highcharts';
// import HC_exporting from 'highcharts/modules/exporting';
// import { GramPanchayatWiseData } from '../GramPanchayatWiseData'; // Import your data file

// HC_exporting(Highcharts);

// const data = GramPanchayatWiseData.SantKabirNagar.CropWiseData; // Access the data for "Nehiya Khurd"




// const CropWiseDistribution = () => {
//   useEffect(() => {
//     if (data && data.length > 0) {
//       const totalArea = data.reduce((total, crop) => total + crop["Area (ha)"], 0);

//       const chart = Highcharts.chart('crop-chart', {
//         chart: {
//           type: 'pie',
//           plotBackgroundColor: null,
//           plotBorderWidth: null,
//           plotShadow: false,
//         },
//         title: {
//           text: 'Cropwise Area Percentage',
//         },
//         tooltip: {
//           pointFormat: '<b>{point.y:.2f} Area (ha)</b>',
//         },
//         plotOptions: {
//           pie: {
//             allowPointSelect: true,
//             cursor: 'pointer',
//             dataLabels: {
//               enabled: true,
//               format: '<b>{point.name}</b>: {point.percentage:.2f} %',
//               connectorColor: 'silver',
//             },
//           },
//         },
//         series: [
//           {
//             type: 'pie',
//             innerSize: '50%',
//             data: data.map((crop) => ({
//               name: crop.Crop,
//               y: crop["Area (ha)"],
//               percentage: ((crop["Area (ha)"] / totalArea) * 100).toFixed(2),
//             })),
//           },
//         ],
//       });
      
//       return () => {
//         chart.destroy();
//       };
//     }
//   }, [data]);

//   return <div id="crop-chart" />;
// };

// export default CropWiseDistribution;



import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { GramPanchayatWiseData } from '../GramPanchayatWiseData';
import { useInView } from 'react-intersection-observer';

HC_exporting(Highcharts);

const data = GramPanchayatWiseData.SantKabirNagar.CropWiseData; // Access the data for "Nehiya Khurd"

const CropWiseDistribution = () => {
  const [ref, inView] = useInView({
    triggerOnce: false, // Trigger only once when it comes into view
    // threshold: 0.2, // 20% of the component should be in view
  });

  useEffect(() => {
    if (inView && data && data.length > 0) {
      const totalArea = data.reduce((total, crop) => total + crop['Area (ha)'], 0);

      const chart = Highcharts.chart('crop-chart', {
        chart: {
          type: 'pie',
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
        },
        title: {
          text: 'Cropwise Area Percentage',
        },
        tooltip: {
          pointFormat: '<b>{point.y:.2f} Area (ha)</b>',
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.2f} %',
              connectorColor: 'silver',
            },
          },
        },
        series: [
          {
            type: 'pie',
            innerSize: '50%',
            data: data.map((crop) => ({
              name: crop.Crop,
              y: crop['Area (ha)'],
              percentage: ((crop['Area (ha)'] / totalArea) * 100).toFixed(2),
            })),
          },
        ],
      });

      return () => {
        chart.destroy();
      };
    }
  }, [inView, data]);

  return <div ref={ref} id="crop-chart" />;
};

export default CropWiseDistribution;