// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { BrowserRouter } from "react-router-dom";
// import { Provider } from "react-redux";
// import store from "./store/index";

// function Authentication() {

//   return (
//     <div>
//<Provider store={store}>
//<BrowserRouter>
 // <App />
//</BrowserRouter>
//</Provider>
//     </div>
//   );
// }

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<Authentication />);

// reportWebVitals();

// --------------------------------------------------

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
// import { AuthContextProvider } from "./components/AuthContext";
import { Provider } from "react-redux";
import store from "./store/index";

function Authentication() {
  const [isLoggedIn, setIsLoggedIn] = useState(
    sessionStorage.getItem("isLoggedIn") === "true"
  );

  const handleLogin = () => {
    const username = prompt("Username:");
    const password = prompt("Password:");

    if (username === "user" && password === "pass") {
      sessionStorage.setItem("isLoggedIn", "true");
      setIsLoggedIn(true);
    } else {
      alert("Incorrect username or password. Please try again.");
    }
  };

  useEffect(() => {
    if (!isLoggedIn) {
      handleLogin();
    }
  }, []); // Empty dependency array ensures the effect runs only once on mount

  return (
    <div>
      {isLoggedIn ? (
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      ) : null}
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Authentication />);

reportWebVitals();
