// import React, { useEffect } from 'react';
// import Highcharts from 'highcharts';
// import HC_exporting from 'highcharts/modules/exporting';
// import { GramPanchayatWiseData } from '../GramPanchayatWiseData'; // Import your data file
// import { useSelector } from "react-redux";

// HC_exporting(Highcharts);


// const ActivityWiseEmission = () => {
//   const selectedGramPanchayat = useSelector((state) => state.gramPanchayat.selectedGramPanchayat);
//   // const data = GramPanchayatWiseData[selectedGramPanchayat]?.ActivityWiseEmission || [];
//   const data = GramPanchayatWiseData.SantKabirNagar.ActivityWiseEmission; // Access the data for "Nehiya Khurd"

//   useEffect(() => {
//     Highcharts.chart('ActivityWiseEmission', {
//       chart: {
//         type: 'bar',
//       },
//       title: {
//         text: 'Activity Wise Emission Data',
//       },
//       xAxis: {
//         categories: ['Emissions'],
//       },
//       yAxis: {
//         min: 0,
//         title: {
//           text: 'Emission Value',
//         },
//         stackLabels: {
//           enabled: true,
//           style: {
//             fontWeight: 'bold',
//             color: 'black',
//           },
//         },
//       },
//       legend: {
//         reversed: true,
//       },
//       plotOptions: {
//         series: {
//           stacking: 'normal',
//           dataLabels: {
//             enabled: true,
//           },
//           pointWidth: '50', // Adjust the width here (in pixels)

//         },

//       },
//       series: data,
//     });
//   }, []);

//   return <>
//   {/* {selectedGramPanchayat} */}
//   <div id="ActivityWiseEmission" /></>;
// };

// export default ActivityWiseEmission;



import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { GramPanchayatWiseData } from '../GramPanchayatWiseData';
import { useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';

HC_exporting(Highcharts);

const ActivityWiseEmission = () => {
  const selectedGramPanchayat = useSelector((state) => state.gramPanchayat.selectedGramPanchayat);
  const data = GramPanchayatWiseData.SantKabirNagar.ActivityWiseEmission; // Access the data for "Nehiya Khurd"

  const [ref, inView] = useInView({
    triggerOnce: false, // Trigger only once when it comes into view
    // threshold: 0.2, // 20% of the component should be in view
  });

  useEffect(() => {
    if (inView) {
      Highcharts.chart('ActivityWiseEmission', {
        chart: {
          type: 'bar',
        },
        title: {
          text: 'Activity Wise Emission Data',
        },
        xAxis: {
          categories: ['Emissions'],
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Emission Value',
          },
          stackLabels: {
            enabled: true,
            style: {
              fontWeight: 'bold',
              color: 'black',
            },
          },
        },
        legend: {
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            dataLabels: {
              enabled: true,
            },
            pointWidth: '50', // Adjust the width here (in pixels)
          },
        },
        series: data,
      });
    }
  }, [inView]);

  return <div ref={ref} id="ActivityWiseEmission" />;
};

export default ActivityWiseEmission;
