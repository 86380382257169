import React, { useEffect, useState } from "react";
import VasudhaLogo from "../images/VasudhaLogo.jpg";
import SecondaryHeader from "./SecondaryHeader";
import { useDispatch } from "react-redux";
import { stateActions } from "../store/selectedGramPanchayat";
import { useSelector } from "react-redux";
import { AiOutlineHome } from "react-icons/ai"; // Import the icon component
import LanguageChange from "./LanguageChange";
import Navbar from "./Navbar";

// import "./InternalHeader.css";
// Import your CSS file for InternalHeader styling

const districts = [
  {
    id: "UttarPradesh",
    name: "Uttar Pradesh",
    numberOfPanchayats: 5,
    link: "./UttarPradesh",
  },
  {
    id: "Agra",
    name: "Agra",
    numberOfPanchayats: 5,
    link: "./Agra",
  },
  {
    id: "Mathura",
    name: "Mathura",
    numberOfPanchayats: 5,
    link: "./Mathura",
  },
  {
    id: "Aligarh",
    name: "Aligarh",
    numberOfPanchayats: 5,
    link: "./Aligarh",
  },
  {
    id: "Kasganj",
    name: "Kasganj",
    numberOfPanchayats: 5,
    link: "./Kasganj",
  },
  {
    id: "Etah",
    name: "Etah",
    numberOfPanchayats: 5,
    link: "./Etah",
  },
  {
    id: "Mainpuri",
    name: "Mainpuri",
    numberOfPanchayats: 5,
    link: "./Mainpuri",
  },
  {
    id: "Lalitpur",
    name: "Lalitpur",
    numberOfPanchayats: 5,
    link: "./Lalitpur",
  },
  {
    id: "Jhansi",
    name: "Jhansi",
    numberOfPanchayats: 5,
    link: "./Jhansi",
  },
  {
    id: "Mahoba",
    name: "Mahoba",
    numberOfPanchayats: 5,
    link: "./Mahoba",
  },
  {
    id: "Hamirpur",
    name: "Hamirpur",
    numberOfPanchayats: 5,
    link: "./Hamirpur",
  },
  {
    id: "Banda",
    name: "Banda",
    numberOfPanchayats: 5,
    link: "./Banda",
  },
  {
    id: "Chitrakoot",
    name: "Chitrakoot",
    numberOfPanchayats: 5,
    link: "./Chitrakoot",
  },
  {
    id: "Moradabad",
    name: "Moradabad",
    numberOfPanchayats: 5,
    link: "./Moradabad",
  },
  {
    id: "Rampur",
    name: "Rampur",
    numberOfPanchayats: 5,
    link: "./Rampur",
  },
  {
    id: "Badaun",
    name: "Badaun",
    numberOfPanchayats: 5,
    link: "./Badaun",
  },
  {
    id: "Shahjahanpur",
    name: "Shahjahanpur",
    numberOfPanchayats: 5,
    link: "./Shahjahanpur",
  },
  {
    id: "Shravasti",
    name: "Shravasti",
    numberOfPanchayats: 5,
    link: "./Shravasti",
  },
  {
    id: "Sonbhadra",
    name: "Sonbhadra",
    numberOfPanchayats: 5,
    link: "./Sonbhadra",
  },
  {
    id: "Mirzapur",
    name: "Mirzapur",
    numberOfPanchayats: 5,
    link: "./Mirzapur",
  },
  {
    id: "Bhadohi",
    name: "Bhadohi",
    numberOfPanchayats: 5,
    link: "./Bhadohi",
  },
  {
    id: "Chandauli",
    name: "Chandauli",
    numberOfPanchayats: 5,
    link: "./Chandauli",
  },
  {
    id: "Jaunpur",
    name: "Jaunpur",
    numberOfPanchayats: 5,
    link: "./Jaunpur",
  },
  {
    id: "Pratapgarh",
    name: "Pratapgarh",
    numberOfPanchayats: 5,
    link: "./Pratapgarh",
  },
  {
    id: "Sultanpur",
    name: "Sultanpur",
    numberOfPanchayats: 5,
    link: "./Sultanpur",
  },
  {
    id: "Amethi",
    name: "Amethi",
    numberOfPanchayats: 5,
    link: "./Amethi",
  },
  {
    id: "SantKabirNagar",
    name: "Sant Kabir Nagar",
    numberOfPanchayats: 5,
    link: "./SantKabirNagar",
  },
  {
    id: "SiddharthNagar",
    name: "Siddharth Nagar",
    numberOfPanchayats: 5,
    link: "./SiddharthNagar",
  },
  {
    id: "Balrampur",
    name: "Balrampur",
    numberOfPanchayats: 5,
    link: "./Balrampur",
  },
  {
    id: "Bahraich",
    name: "Bahraich",
    numberOfPanchayats: 5,
    link: "./Bahraich",
  },
  {
    id: "Gonda",
    name: "Gonda",
    numberOfPanchayats: 5,
    link: "./Gonda",
  },
  {
    id: "Farrukhabad",
    name: "Farrukhabad",
    numberOfPanchayats: 5,
    link: "./Farrukhabad",
  },
  {
    id: "Kannauj",
    name: "Kannauj",
    numberOfPanchayats: 5,
    link: "./Kannauj",
  },
  {
    id: "Hardoi",
    name: "Hardoi",
    numberOfPanchayats: 5,
    link: "./Hardoi",
  },
  {
    id: "Unnao",
    name: "Unnao",
    numberOfPanchayats: 5,
    link: "./Unnao",
  },
  {
    id: "KanpurNagar",
    name: "Kanpur Nagar",
    numberOfPanchayats: 5,
    link: "./KanpurNagar",
  },
  {
    id: "KanpurDehat",
    name: "Kanpur Dehat",
    numberOfPanchayats: 5,
    link: "./KanpurDehat",
  },
  {
    id: "Auraiya",
    name: "Auraiya",
    numberOfPanchayats: 5,
    link: "./Auraiya",
  },
  {
    id: "Raibareli",
    name: "Rai Bareli",
    numberOfPanchayats: 5,
    link: "./Raibareli",
  },
  {
    id: "Kaushambi",
    name: "Kaushambi",
    numberOfPanchayats: 5,
    link: "./Kaushambi",
  },
  // Add more districts as needed
];

const InternalHeader = () => {
  const dispatch = useDispatch();

  const selectedTab = useSelector((state) => state.gramPanchayat.selectedTab);

  const [isSticky, setIsSticky] = useState(false);
  const [activeTab, setActiveTab] = useState("GP Profile"); // Initialize with the default tab
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [hoveredPath, setHoveredPath] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || window.pageYOffset;
      setIsSticky(scrollY > 20);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleTabClick = (tab) => {
    dispatch(stateActions.changeselectedTab({ selectedTab: tab }));

    setActiveTab(tab);
  };

  const handleMouseEnter = (pathId) => {
    setHoveredPath(pathId);
  };

  const handleMouseLeave = () => {
    setHoveredPath(null);
  };

  return (
    <>
      <nav style={{ overflowX: "hidden" }} className={`navbar navbar-light `}>
        <div className="header">
          <a
            className="navbar-brand"
            href="/"
            style={{
              fontWeight: "700",
              fontSize: "20px",
              lineHeight: "26.88px",
            }}
          >
            Climate Smart Gram Panchayat <br></br>{" "}
            <span
              style={{
                fontWeight: "500",
                fontSize: "20px",
                lineHeight: "29.26px",
              }}
            >
              Uttar Pradesh
            </span>
          </a>
          <div className="d-flex align-items-right">
          {/* <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
          <LanguageChange/>
          </div> */}
            <img
              src={VasudhaLogo}
              alt="Logo"
              className="logo img-fluid"
              style={{ maxHeight: "60px" }}
            />
          </div>
        </div>
      </nav>
      <div
        style={{
          width: "100%",
          height: "80px",
          borderTop: "11px solid #009EE3",
          background:
            "linear-gradient(180deg, #CEEBFB 0%, rgba(191, 231, 254, 0.00) 125.44%), #FFF",
        }}
        className={`${isSticky ? "sticky-header" : ""}`}
      >
        <Navbar/>

        <ul className="secondaryHeader" style={{background:
            "linear-gradient(180deg, #CEEBFB 0%, rgba(191, 231, 254, 0.00) 125.44%), #FFF",}}>
          {/* <li className="homeIcon" onClick={() => handleTabClick("GP Profile") }>
            <AiOutlineHome style={{ fontSize: "30px" }} />
          </li> */}

          <li
            className="homeIcon"
            onClick={() => {
              handleTabClick("GP Profile");
              window.location.href = "/";
            }}
          >
            <AiOutlineHome style={{ fontSize: "30px" }} />
          </li>

          <li
            className={selectedTab === "GP Profile" ? "active-tab" : ""}
            onClick={() => handleTabClick("GP Profile")}
          >
            GP Profile
          </li>
          <li
            className={selectedTab === "GHG Profile" ? "active-tab" : ""}
            onClick={() => handleTabClick("GHG Profile")}
          >
            GHG Profile
          </li>
          <li
            className={selectedTab === "Key Recommendation" ? "active-tab" : ""}
            onClick={() => handleTabClick("Key Recommendation")}
          >
            Key Recommendation
          </li>
          <li
            className={
              selectedTab === "Alignment with SDGs" ? "active-tab" : ""
            }
            onClick={() => handleTabClick("Alignment with SDGs")}
          >
            Alignment with SDGs
          </li>

          {/* <li
            class="nav-item dropdown"
            style={{
              border: "1px solid #009EE3",
              paddingLeft: "15px",
              paddingRight: "15px",
              borderRadius: "10px",
              width: "285px",
              height: "41.93px",
              textAlign: "center",
            }}
          >
            <a
              class="nav-link dropdown-toggle"
              data-toggle="dropdown"
              href=""
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              style={{
                border: "none",
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "21px",
                color: "black",
              }}
            >
              {" "}
              Change the District
            </a>
            <div
              className="dropdown-menu"
              style={{
                maxHeight: "250px",
                overflowY: "scroll",
                width: "285px",
                marginLeft: "-15px",
                marginTop: "5px",
                textAlign: "center",
              }}
            >
              {districts.map((district) => (
                <div
                  key={district.id}
                  onClick={() => setSelectedDistrict(district.name)}
                  onMouseEnter={() => handleMouseEnter(district.id)}
                  onMouseLeave={handleMouseLeave}
                  className={`district-link ${
                    hoveredPath?.toLowerCase() ===
                    district.name.toLowerCase().split(" ").join("")
                      ? "hovered-link"
                      : ""
                  }`}
                  style={{
                    borderBottom: "none",
                    textDecoration: "none",
                    color: "black",
                    cursor: "pointer",
                  }}
                >
                  <p>{district.name}</p>
                </div>
              ))}
            </div>
          </li> */}
          {/* <ul class="nav nav-tabs w-100" style={{ borderBottom: "none" }}>
                
              </ul> */}
        </ul>
      </div>
    </>
  );
};

export default InternalHeader;
