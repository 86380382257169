import { createSlice } from "@reduxjs/toolkit";

const initialAuthState = {
  selectedGramPanchayat: "ajay",
  selectedTab:'GP Profile'
};

const stateSlice = createSlice({
  name: "gramPanchayat", // Update the name to "gramPanchayat"
  initialState: initialAuthState,
  reducers: {
    changeselectedGramPanchayat: (state, action) => {
      state.selectedGramPanchayat = action.payload.selectedGramPanchayat;
    },
    changeselectedTab: (state, action) => {
      state.selectedTab = action.payload.selectedTab;
    },
  },
});

export const stateActions = stateSlice.actions;
export default stateSlice.reducer;
