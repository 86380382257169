// import React, { useEffect } from 'react';
// import Highcharts from 'highcharts';
// import HC_exporting from 'highcharts/modules/exporting';
// import { GramPanchayatWiseData } from '../GramPanchayatWiseData'; // Import your data file


// HC_exporting(Highcharts);

// const data = GramPanchayatWiseData.SantKabirNagar.AgricultureOnlyDependentHouseholds; // Access the data for "Nehiya Khurd"

// const TypeOfAgricultureDependentHousholds = () => {
//   useEffect(() => {
//     const prepareHouseholdsData = () => {
//       if (data) {
//         return Object.entries(data).map(([type, count]) => ({
//           name: type,
//           y: count,
//         }));
//       } else {
//         return [];
//       }
//     };

//     const barChartOptions = {
//       chart: {
//         type: "bar",
//         renderTo: 'TypeOfAgricultureDependentHousholds', // Specify the TypeOfAgricultureDependentHousholds ID
//       },
//       title: {
//         text: "Type of Agriculture (only) dependent households in the GP        ",
//       },
//       tooltip: {
//         pointFormat: " <b>{point.y:.2f} </b>",
//       },
//       xAxis: {
//         categories: prepareHouseholdsData().map((data) => data.name),
//       },
//       yAxis: {
//         title: {
//           text: false,
//         },
//       },
//       legend: {
//         enabled: false, // Hide the legend
//       },
//       series: [
//         {
//           data: prepareHouseholdsData().map((data) => data.y),
//         },
//       ],
//     };

//     // Create the Highcharts chart inside the useEffect hook
//     Highcharts.chart(barChartOptions);

//     return () => {
//       // Destroy the chart when the component unmounts to prevent memory leaks
//       Highcharts.charts[Highcharts.charts.length - 1].destroy();
//     };
//   }, [data]);

//   return <div id="TypeOfAgricultureDependentHousholds" />;
// };

// export default TypeOfAgricultureDependentHousholds;



import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { GramPanchayatWiseData } from '../GramPanchayatWiseData';
import { useInView } from 'react-intersection-observer';

HC_exporting(Highcharts);

const data = GramPanchayatWiseData.SantKabirNagar.AgricultureOnlyDependentHouseholds; // Access the data for "Nehiya Khurd"

const TypeOfAgricultureDependentHousholds = () => {
  const [ref, inView] = useInView({
    triggerOnce: false, // Trigger only once when it comes into view
    // threshold: 0.2, // 20% of the component should be in view
  });

  useEffect(() => {
    let chart = null;
  
    if (inView) {
      const prepareHouseholdsData = () => {
        if (data) {
          return Object.entries(data).map(([type, count]) => ({
            name: type,
            y: count,
          }));
        } else {
          return [];
        }
      };
  
      const barChartOptions = {
        chart: {
          type: 'bar',
          renderTo: 'TypeOfAgricultureDependentHousholds', // Specify the TypeOfAgricultureDependentHousholds ID
        },
        title: {
          text: 'Type of Agriculture (only) dependent households in the GP',
        },
        tooltip: {
          pointFormat: ' <b>{point.y:.2f} </b>',
        },
        xAxis: {
          categories: prepareHouseholdsData().map((data) => data.name),
        },
        yAxis: {
          title: {
            text: false,
          },
        },
        legend: {
          enabled: false, // Hide the legend
        },
        series: [
          {
            data: prepareHouseholdsData().map((data) => data.y),
          },
        ],
      };
  
      // Create the Highcharts chart inside the useEffect hook
      chart = Highcharts.chart(barChartOptions);
    }
  
    return () => {
      // Check if a valid chart instance exists before destroying it
      if (chart) {
        chart.destroy();
      }
    };
  }, [data, inView]);
  
  return <div ref={ref} id="TypeOfAgricultureDependentHousholds" />;
};

export default TypeOfAgricultureDependentHousholds;
